//import { ValidatedMethod } from 'meteor/mdg:validated-method';
import SimpleSchema from 'simpl-schema';
import { Meteor } from 'meteor/meteor';

import log from 'loglevel';

/**
 * Method to perform captcha login.
 *
 * @method captcha.login
 * @param {String} token - The captcha token.
 * @returns {Boolean} - Returns true if the captcha verification passed, otherwise throws an error.
 */
export const captchalogin = new ValidatedMethod({
  name: 'captcha.login',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    token: { type: String }
  }).validator(),

  run: async function ({ token }) {
    var IPAddress = '';
    if (Meteor.isServer) {
      const { verifyCaptcha } = require('../../../server/methods/captcha');
      IPAddress = this.connection.clientAddress;

      console.log('captchalogin:', IPAddress, token);
      // var verifyCaptchaResponse = verifyCaptcha.call({
      var verifyCaptchaResponse = await verifyCaptcha(IPAddress, token);

      log.info('Action :' + token + '    ' + verifyCaptchaResponse.success);
      if (!verifyCaptchaResponse.success) {
        log.info('reCAPTCHA check failed!', verifyCaptchaResponse);
        throw new Meteor.Error(
          422,
          'reCAPTCHA Failed: ' + verifyCaptchaResponse.error
        );
      } else {
        log.info('reCAPTCHA verification passed!');
        return true;
      }
    }
    //	log.info(Animal);
  }
});

export default {};
