import { Mongo } from 'meteor/mongo';

import { z } from 'zod';

const settingsSchema = z.object({
  deleted: z.boolean(), // OK
  ownerId: z.string(), // OK
  calendar: z.object({
    dayBoundaries: z.object({
      start: z.string(),
      end: z.string()
    }),
    event: z.object({
      showEventHours: z.boolean()
    })
  })
});

const Settings = new Mongo.Collection('settings', {
  idGeneration: 'MONGO'
});

if (Meteor.isServer) {
  Settings.withSchema(settingsSchema);
  Settings.withDates();
  Settings.withUser();
}

export default Settings;
