//import { ValidatedMethod } from 'meteor/mdg:validated-method';
import { Meteor } from 'meteor/meteor';
import SimpleSchema from 'simpl-schema';
import colorLib from '@kurkle/color';
import Events from '../collections/events';
import { DateTime } from 'luxon';
import Locations from '../collections/locations';

function getColorCode() {
  var makeColorCode = '0123456789ABCDEF';
  var code = '#';
  for (var count = 0; count < 6; count++) {
    code = code + makeColorCode[Math.floor(Math.random() * 16)];
  }
  return code;
}

function transparentize(value: string, opacity: number) {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
}

function decimalHourToString(decimalHour: number) {
  let hoursString = '';
  let minutesString = '';
  let hours = Math.floor(decimalHour);

  hoursString = hours < 10 ? '0' + hours.toString() : hours.toString();

  let minutes = Math.round((decimalHour - hours) * 60);
  minutesString = minutes < 10 ? '0' + minutes.toString() : minutes.toString();

  return `${hoursString}h${minutesString}`;
}

/**
 * Method to get statistics for events based on the year.
 *
 * @name getStatsEvent
 * @memberOf Methods
 * @param {Object} params - The parameters for the method.
 * @param {number} params.year - The year for which to retrieve statistics.
 * @returns {Object} chartdata - The chart data containing labels and datasets.
 * @throws {Meteor.Error} methods.getStats.notlogged - If the user is not logged in.
 *
 * @example
 * import { getStats } from '/imports/api/methods/stats';
 *
 * getStats.call({ year: 2023 }, (err, res) => {
 *   if (err) {
 *     console.error('Error:', err);
 *   } else {
 *     console.log('Statistics:', res);
 *   }
 * });
 */
export const getStatsLocation = new ValidatedMethod({
  name: 'getStatsLocations',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    year: { type: Number },
    periode: { type: String, optional: true }
  }).validator(),

  run: async function ({
    year,
    periode = 'week'
  }: {
    year: number;
    periode: string;
  }) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.getStatsLocations.notlogged',
        'Vous devez être loggué.'
      );

    if (Meteor.isServer) {
      let cursor = Events.rawCollection().aggregate([
        {
          $project: {
            location: 1,
            ownerId: 1,
            deleted: 1,
            year: {
              $isoWeekYear: {
                $dateFromString: {
                  dateString: '$start'
                }
              }
            }
          }
        },
        { $match: { ownerId: this.userId, year: year, deleted: false } },
        { $group: { _id: { location: '$location' } } }
      ]);

      let activityTypesTab = await cursor.toArray();

      let periodeProjet = {};
      if (periode == 'week') {
        periodeProjet = {
          $isoWeek: {
            $dateFromString: {
              dateString: '$start'
            }
          }
        };
      } else if (periode == 'month') {
        periodeProjet = {
          $month: {
            $dateFromString: {
              dateString: '$start'
            }
          }
        };
      }

      const cursorTime = Events.rawCollection().aggregate([
        {
          $project: {
            WE: periodeProjet,
            ownerId: 1,
            deleted: 1,
            year: {
              $isoWeekYear: {
                $dateFromString: {
                  dateString: '$start'
                }
              }
            }
          }
        },
        { $match: { ownerId: this.userId, year: year, deleted: false } },
        { $group: { _id: '$WE' } },
        { $sort: { _id: 1 } }
      ]);

      const weTab = await cursorTime.toArray();

      const result = Events.rawCollection().aggregate([
        {
          $project: {
            ownerId: 1,
            location: 1,
            deleted: 1,
            WE: periodeProjet,

            diff: {
              $divide: [
                {
                  $subtract: [
                    {
                      $dateFromString: {
                        dateString: '$end'
                      }
                    },
                    {
                      $dateFromString: {
                        dateString: '$start'
                      }
                    }
                  ]
                },
                1000 * 60 * 60
              ]
            }
          }
        },

        {
          $match: {
            ownerId: this.userId,
            deleted: false
          }
        },
        {
          $group: {
            _id: {
              week: '$WE',
              location: '$location'
            },
            total: {
              $sum: '$diff'
            }
          }
        }
      ]);

      const activitiesTab = await result.toArray();

      let chartdata: {
        labels: (string | number)[];
        datasets: {
          fill: boolean;
          label: string;
          backgroundColor: string;
          borderWidth: number;
          borderRadius: number;
          borderSkipped: boolean;
          data: number[];
        }[];
      } = {
        labels: [],
        datasets: []
      };

      const listOfEventsCursor = Events.rawCollection().aggregate([
        {
          $project: {
            ownerId: 1,
            location: 1,
            deleted: 1
          }
        },
        { $match: { ownerId: this.userId, deleted: false } },
        { $group: { _id: { location: '$location' } } }
      ]);

      const listOfEvents = await listOfEventsCursor.toArray();
      // console.log('listOfEvents:', listOfEvents);

      const listOfLocations = await Locations.find({
        ownerId: this.userId
      }).fetchAsync();

      activityTypesTab.forEach((element, index: number) => {
        let label;
        let event = listOfEvents.find(
          (ele) => ele._id.location == element._id.location
        );
        //console.log('event:', event, element);
        let location = listOfLocations.find(
          (ele) => ele.title == element._id.location
        );
        if (event) {
          if (element._id.location === null || element._id.location === '') {
            label = 'Vide';

            location.bgColor = getColorCode();
          } else label = element._id.location;

          chartdata.datasets[index] = {
            fill: false,
            label: label,
            backgroundColor: transparentize(location.bgColor, 0.5),
            borderWidth: 2,
            borderRadius: 5,
            borderSkipped: false,
            data: []
          };
        }
      });

      //console.log('chartdata:', chartdata);
      const currentWeek = parseInt(DateTime.now().toFormat('W'), 10);

      const weTabIds = weTab.map((item) => item._id);
      const min = Math.min(...weTabIds);
      let max = Math.max(...weTabIds);

      if (max < currentWeek) max = currentWeek;

      const presentWeek = Array.from({ length: max - min + 1 }, () => ({
        indexType: -2,
        isPresentInTheWeek: false
      }));

      activitiesTab.forEach((element) => {
        const weekIndex = element._id.week - min;
        const indexType = activityTypesTab.findIndex(
          (i) => i._id.location === element._id.location
        );

        if (indexType !== -1) {
          chartdata.datasets[indexType].data[weekIndex] = element.total;
          chartdata.labels[weekIndex] = element._id.week;
          presentWeek[weekIndex] = { indexType, isPresentInTheWeek: true };
        }
      });

      presentWeek.forEach((ele, index) => {
        if (!ele.isPresentInTheWeek && ele.indexType !== -2) {
          chartdata.datasets[ele.indexType].data[index] = 0;
          chartdata.labels[index] = index + min;
        }
      });

      return chartdata;
    }
  }
});

export const getStatsLocationPieWeek = new ValidatedMethod({
  name: 'getStatsLocationPieWeek',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    week: { type: Number }
  }).validator(),

  run: async function ({ week }: { week: number }) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.getStatsLocationPieWeek.notlogged',
        'Vous devez être loggué.'
      );

    if (Meteor.isServer) {
      let cursor = Events.rawCollection().aggregate([
        {
          $project: {
            ownerId: 1,
            deleted: 1,
            location: 1,
            week: {
              $isoWeek: {
                $dateFromString: {
                  dateString: '$start'
                }
              }
            }
          }
        },
        { $match: { ownerId: this.userId, week: week, deleted: false } },
        { $group: { _id: { location: '$location' } } }
      ]);

      let activityTypesTab = await cursor.toArray();

      const result = Events.rawCollection().aggregate([
        {
          $project: {
            ownerId: 1,
            location: 1,
            deleted: 1,

            WE: {
              $isoWeek: {
                $dateFromString: {
                  dateString: '$start'
                }
              }
            },

            diff: {
              $divide: [
                {
                  $subtract: [
                    {
                      $dateFromString: {
                        dateString: '$end'
                      }
                    },
                    {
                      $dateFromString: {
                        dateString: '$start'
                      }
                    }
                  ]
                },
                1000 * 60 * 60
              ]
            }
          }
        },

        {
          $match: {
            ownerId: this.userId,
            WE: week,
            deleted: false
          }
        },
        {
          $group: {
            _id: {
              location: '$location'
            },
            total: {
              $sum: '$diff'
            }
          }
        }
      ]);

      const activitiesTab = await result.toArray();
      //console.log('activitiesTab:', activitiesTab);

      let chartdata: {
        labels: (string | number)[];
        datasets: {
          fill: boolean;
          label: string;
          backgroundColor: string[];
          borderWidth: number;
          borderRadius: number;
          borderSkipped: boolean;
          hoverOffset: number;
          data: number[];
        }[];
      } = {
        labels: [],
        datasets: [
          {
            fill: false,
            label: '',
            backgroundColor: [],
            borderWidth: 2,
            borderRadius: 5,
            hoverOffset: 10,
            borderSkipped: false,
            data: []
          }
        ]
      };

      let chartdataJour: {
        labels: (string | number)[];
        datasets: {
          label: string;
          backgroundColor: string;
          data: number[];
        }[];
      } = {
        labels: [''],
        datasets: [
          {
            label: '',
            data: [],
            backgroundColor: ''
          }
        ]
      };

      const listOfEventsCursor = Events.rawCollection().aggregate([
        {
          $project: {
            ownerId: 1,
            location: 1,
            WE: {
              $isoWeek: {
                $dateFromString: {
                  dateString: '$start'
                }
              }
            },

            deleted: 1
          }
        },
        { $match: { ownerId: this.userId, WE: week, deleted: false } },
        { $group: { _id: { location: '$location' } } }
      ]);

      const listOfEvents = await listOfEventsCursor.toArray();
      //console.log('listOfEventsPie:', listOfEvents);

      const listOfLocations = await Locations.find({
        ownerId: this.userId
      }).fetchAsync();

      let sum = 0;
      activityTypesTab.forEach((element, index: number) => {
        let label;

        let event = listOfEvents.find(
          (ele) => ele._id.location == element._id.location
        );
        //console.log('event', event);
        let location = listOfLocations.find(
          (ele) => ele.title == element._id.location
        );
        if (event) {
          if (element._id.location === null || element._id.location === '') {
            label = 'Vide';
            location.bgColor = getColorCode();
          } else label = element._id.location;

          //console.log('label  :', label);
          chartdata.labels[index] = label;
          chartdata.datasets[0].backgroundColor[index] = transparentize(
            location.bgColor,
            0.5
          );
        }
        //console.log('elementPie:', element, activitiesTab);
        let total = activitiesTab.find(
          (ele) => ele._id.location === element._id.location
        );

        if (total) {
          chartdata.datasets[0].data[index] = total.total;
          sum += total.total;
        } else {
          chartdata.datasets[0].data[index] = 0;
        }
      });

      let weekStatPercentage: {
        label: string | number;
        total: string;
        percentage: string;
      }[] = [];

      chartdata.datasets[0].data.forEach((element, index) => {
        weekStatPercentage[index] = {
          label: chartdata.labels[index],
          total: decimalHourToString(element),
          percentage: (Math.round((element / sum) * 100 * 100) / 100).toFixed(2)
        };
        const weekAverage = element / 7;
        chartdataJour.datasets[index] = {
          label: chartdata.labels[index].toString(),
          data: [weekAverage],
          backgroundColor: chartdata.datasets[0].backgroundColor[index]
        };
      });

      //console.log('Day', chartdataJour.datasets[0]);
      return { chartdata, weekStatPercentage, chartdataJour };
    }
  }
});
