// put this in a file that's imported on both the client and server
import { SoftDelete } from 'meteor/jam:soft-delete';

SoftDelete.configure({
  deleted: 'deleted', // the field name used for the boolean flag. you can update to your preference, e.g. 'isDeleted'
  deletedAt: 'deletedAt', // add the name of the field you'd like to use for a deletedAt timestamp, e.g. 'deletedAt', if you want to include it on your docs
  autoFilter: true, // automatically adds the { [deleted]: false } filter to your queries
  overrideRemove: true, // overrides the Collection.removeAsync method to make it a soft delete instead
  exclude: ['roles', 'role-assignment', 'user_status_sessions'] // exclude specific collections from using soft delete. defaults to excluding the collections created the meteor roles package
});
