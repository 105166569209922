import { Mongo } from 'meteor/mongo';

import { z } from 'zod';

const topEventsSchema = z.object({
  title: z.string(), // OK
  calendarId: z.string(), // OK
  ownerId: z.string(), // OK
  total: z.number(), // OK
  deleted: z.boolean() // OK
});

const TopEvents = new Mongo.Collection('topEvents', {
  idGeneration: 'MONGO'
});

if (Meteor.isServer) {
  TopEvents.withSchema(topEventsSchema);
  TopEvents.withDates();
  TopEvents.withUser();
}

export default TopEvents;
