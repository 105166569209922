//import { ValidatedMethod } from 'meteor/mdg:validated-method';
import { Meteor } from 'meteor/meteor';
import SimpleSchema from 'simpl-schema';
import colorLib from '@kurkle/color';
import Events from '../collections/events';
import Categories from '../collections/categories';
import { DateTime } from 'luxon';

function getColorCode() {
  var makeColorCode = '0123456789ABCDEF';
  var code = '#';
  for (var count = 0; count < 6; count++) {
    code = code + makeColorCode[Math.floor(Math.random() * 16)];
  }
  return code;
}

function transparentize(value: string, opacity: number) {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
}

function decimalHourToString(decimalHour: number) {
  let hoursString = '';
  let minutesString = '';
  let hours = Math.floor(decimalHour);

  hoursString = hours < 10 ? '0' + hours.toString() : hours.toString();

  let minutes = Math.round((decimalHour - hours) * 60);
  minutesString = minutes < 10 ? '0' + minutes.toString() : minutes.toString();

  return `${hoursString}h${minutesString}`;
}

/**
 * Method to get statistics based on events for a given year and period.
 *
 * @name getStats
 * @rateLimit
 *   @property {number} numRequests - Maximum number of requests allowed.
 *   @property {number} timeInterval - Time interval in milliseconds for the rate limit.
 * @validate
 *   @property {number} year - The year for which statistics are to be fetched.
 *   @property {string} [periode] - The period for grouping statistics, either 'week' or 'month'. Defaults to 'month'.
 *
 * @param {Object} params - The parameters for the method.
 * @param {number} params.year - The year for which statistics are to be fetched.
 * @param {string} [params.periode='month'] - The period for grouping statistics.
 *
 * @throws {Meteor.Error} If the user is not logged in.
 *
 * @returns {Promise<Object>} The chart data containing labels and datasets.
 *
 * @example
 * import { getStats } from '/imports/api/methods/stats';
 *
 * getStats.callAsync({ year: 2023, periode: 'week' })
 *   .then(chartdata => {
 *     console.log(chartdata);
 *   })
 *   .catch(error => {
 *     console.error(error);
 *   });
 */
export const getStats = new ValidatedMethod({
  name: 'getStats',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    year: { type: Number },
    periode: { type: String, optional: true }
  }).validator(),

  run: async function ({
    year,
    periode = 'week'
  }: {
    year: number;
    periode: string;
  }) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.getStats.notlogged',
        'Vous devez être loggué.'
      );

    if (Meteor.isServer) {
      let cursor = Events.rawCollection().aggregate([
        {
          $project: {
            calendarId: 1,
            ownerId: 1,
            deleted: 1,
            year: {
              $isoWeekYear: {
                $dateFromString: {
                  dateString: '$start'
                }
              }
            }
          }
        },
        { $match: { ownerId: this.userId, year: year, deleted: false } },
        { $group: { _id: '$calendarId' } }
      ]);

      let activityTypesTab = await cursor.toArray();

      let periodeProjet = {};
      if (periode == 'week') {
        periodeProjet = {
          $isoWeek: {
            $dateFromString: {
              dateString: '$start'
            }
          }
        };
      } else if (periode == 'month') {
        periodeProjet = {
          $month: {
            $dateFromString: {
              dateString: '$start'
            }
          }
        };
      }

      /**
       * Aggregates event data to retrieve a sorted list of unique project periods (WE) for the specified user and year.
       *
       * The aggregation pipeline performs the following steps:
       * 1. Projects the necessary fields including the project period (WE), ownerId, deleted status, and the year derived from the event start date.
       * 2. Matches documents that belong to the current user, are from the specified year, and are not marked as deleted.
       * 3. Groups the documents by the project period (WE) to get unique periods.
       * 4. Sorts the resulting periods in ascending order.
       *
       * @param {string} this.userId - The ID of the current user.
       * @param {number} year - The year to filter events by.
       * @returns {AggregationCursor} - A cursor to the aggregated results.
       */
      const cursorTime = Events.rawCollection().aggregate([
        {
          $project: {
            WE: periodeProjet,
            ownerId: 1,
            deleted: 1,
            year: {
              $isoWeekYear: {
                $dateFromString: {
                  dateString: '$start'
                }
              }
            }
          }
        },
        { $match: { ownerId: this.userId, year: year, deleted: false } },
        { $group: { _id: '$WE' } },
        { $sort: { _id: 1 } }
      ]);

      const weTab = await cursorTime.toArray();

      const result = Events.rawCollection().aggregate([
        {
          $project: {
            ownerId: 1,
            calendarId: 1,
            deleted: 1,
            WE: periodeProjet,

            diff: {
              $divide: [
                {
                  $subtract: [
                    {
                      $dateFromString: {
                        dateString: '$end'
                      }
                    },
                    {
                      $dateFromString: {
                        dateString: '$start'
                      }
                    }
                  ]
                },
                1000 * 60 * 60
              ]
            }
          }
        },

        {
          $match: {
            ownerId: this.userId,
            deleted: false
          }
        },
        {
          $group: {
            _id: {
              week: '$WE',
              calendarid: '$calendarId'
            },
            total: {
              $sum: '$diff'
            }
          }
        }
      ]);

      const activitiesTab = await result.toArray();

      let chartdata: {
        labels: (string | number)[];
        datasets: {
          fill: boolean;
          label: string;
          backgroundColor: string;
          borderWidth: number;
          borderRadius: number;
          borderSkipped: boolean;
          data: number[];
        }[];
      } = {
        labels: [],
        datasets: []
      };

      const listOfCategories = await Categories.find({
        ownerId: this.userId
      }).fetchAsync();

      activityTypesTab.forEach((element, index) => {
        let label;
        let category = listOfCategories.find(
          (ele) => ele.calendarId == element._id
        );
        if (category) {
          if (element._id === null || element._id === '') {
            label = 'Vide';

            category.config.lightColors.container = getColorCode();
          } else label = category.title;
          chartdata.datasets[index] = {
            fill: false,
            label: label,
            backgroundColor: transparentize(
              category.config.lightColors.container,
              0.5
            ),
            borderWidth: 2,
            borderRadius: 5,
            borderSkipped: false,
            data: []
          };
        }
      });

      const currentWeek = parseInt(DateTime.now().toFormat('W'), 10);

      const weTabIds = weTab.map((item) => item._id);
      const min = Math.min(...weTabIds);
      let max = Math.max(...weTabIds);

      if (max < currentWeek) max = currentWeek;

      const presentWeek = Array.from({ length: max - min + 1 }, () => ({
        indexType: -2,
        isPresentInTheWeek: false
      }));

      activitiesTab.forEach((element) => {
        const weekIndex = element._id.week - min;
        const indexType = activityTypesTab.findIndex(
          (i) => i._id === element._id.calendarid
        );

        if (indexType !== -1) {
          chartdata.datasets[indexType].data[weekIndex] = element.total;
          chartdata.labels[weekIndex] = element._id.week;
          presentWeek[weekIndex] = { indexType, isPresentInTheWeek: true };
        }
      });

      presentWeek.forEach((ele, index) => {
        if (!ele.isPresentInTheWeek && ele.indexType !== -2) {
          chartdata.datasets[ele.indexType].data[index] = 0;
          chartdata.labels[index] = index + min;
        }
      });

      return chartdata;
    }
  }
});

export const getStatsPieWeek = new ValidatedMethod({
  name: 'getStatsPieWeek',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    week: { type: Number }
  }).validator(),

  run: async function ({ week }: { week: number }) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.getStatsPieWeek.notlogged',
        'Vous devez être loggué.'
      );

    if (Meteor.isServer) {
      let cursor = Events.rawCollection().aggregate([
        {
          $project: {
            calendarId: 1,
            ownerId: 1,
            deleted: 1,
            week: {
              $isoWeek: {
                $dateFromString: {
                  dateString: '$start'
                }
              }
            }
          }
        },
        { $match: { ownerId: this.userId, week: week, deleted: false } },
        { $group: { _id: '$calendarId' } }
      ]);

      let activityTypesTab = await cursor.toArray();

      const result = Events.rawCollection().aggregate([
        {
          $project: {
            ownerId: 1,
            calendarId: 1,
            deleted: 1,
            WE: {
              $isoWeek: {
                $dateFromString: {
                  dateString: '$start'
                }
              }
            },

            diff: {
              $divide: [
                {
                  $subtract: [
                    {
                      $dateFromString: {
                        dateString: '$end'
                      }
                    },
                    {
                      $dateFromString: {
                        dateString: '$start'
                      }
                    }
                  ]
                },
                1000 * 60 * 60
              ]
            }
          }
        },

        {
          $match: {
            ownerId: this.userId,
            WE: week,
            deleted: false
          }
        },
        {
          $group: {
            _id: {
              calendarid: '$calendarId'
            },
            total: {
              $sum: '$diff'
            }
          }
        }
      ]);

      const activitiesTab = await result.toArray();

      let chartdata: {
        labels: (string | number)[];
        datasets: {
          fill: boolean;
          label: string;
          backgroundColor: string[];
          borderWidth: number;
          borderRadius: number;
          borderSkipped: boolean;
          hoverOffset: number;
          data: number[];
        }[];
      } = {
        labels: [],
        datasets: [
          {
            fill: false,
            label: '',
            backgroundColor: [],
            borderWidth: 2,
            borderRadius: 5,
            hoverOffset: 10,
            borderSkipped: false,
            data: []
          }
        ]
      };

      let chartdataJour: {
        labels: (string | number)[];
        datasets: {
          label: string;
          backgroundColor: string;
          data: number[];
        }[];
      } = {
        labels: [''],
        datasets: [
          {
            label: '',
            data: [],
            backgroundColor: ''
          }
        ]
      };

      const listOfCategories = await Categories.find({
        ownerId: this.userId
      }).fetchAsync();

      let sum = 0;
      activityTypesTab.forEach((element, index) => {
        let label;
        let category = listOfCategories.find(
          (ele) => ele.calendarId == element._id
        );
        if (category) {
          if (element._id === null || element._id === '') {
            label = 'Vide';
            category.config.lightColors.container = getColorCode();
          } else label = category.title;

          chartdata.labels[index] = label;
          chartdata.datasets[0].backgroundColor[index] = transparentize(
            category.config.lightColors.container,
            0.5
          );
        }
        let total = activitiesTab.find(
          (ele) => ele._id.calendarid === element._id
        );

        if (total) {
          chartdata.datasets[0].data[index] = total.total;
          sum += total.total;
        } else {
          chartdata.datasets[0].data[index] = 0;
        }
      });

      let weekStatPercentage: {
        label: string | number;
        total: string;
        percentage: string;
      }[] = [];

      chartdata.datasets[0].data.forEach((element, index) => {
        weekStatPercentage[index] = {
          label: chartdata.labels[index],
          total: decimalHourToString(element),
          percentage: (Math.round((element / sum) * 100 * 100) / 100).toFixed(2)
        };
        const weekAverage = element / 7;
        chartdataJour.datasets[index] = {
          label: chartdata.labels[index].toString(),
          data: [weekAverage],
          backgroundColor: chartdata.datasets[0].backgroundColor[index]
        };
      });

      //console.log('Day', chartdataJour.datasets[0]);
      return { chartdata, weekStatPercentage, chartdataJour };
    }
  }
});
