//import { ValidatedMethod } from 'meteor/mdg:validated-method';
import { Meteor } from 'meteor/meteor';
import SimpleSchema from 'simpl-schema';
import Locations from '../collections/locations';
import Events from '../collections/events';

/**
 * Method to get user settings.
 *
 * @name getsetti
 * @rateLimit {Object} rateLimit - Rate limit configuration.
 * @rateLimit.numRequests {number} - Number of requests allowed.
 * @rateLimit.timeInterval {number} - Time interval for rate limiting in milliseconds.
 * @validate {SimpleSchema} - Schema validator.
 *
 * @throws {Meteor.Error} If the user is not logged in.
 *
 * @returns {Promise<Object>} User settings.
 */
export const getLocations = new ValidatedMethod({
  name: 'getlocations',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({}).validator(),

  run: async function ({}) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.getlocations.notlogged',
        'Vous devez être loggué.'
      );
    if (Meteor.isServer) {
      return await Locations.find(
        {
          ownerId: this.userId
        },
        {
          fields: {
            _id: 0,
            ownerId: 0,
            userId: 0,
            createdAt: 0,
            updatedAt: 0,
            deleted: 0
          },
          sort: { title: 1 }
        }
      ).fetchAsync();
    }
  }
});

export const deleteLocation = new ValidatedMethod({
  name: 'deletelocation',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    value: String
  }).validator(),

  run: async function ({ value }) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.deletelocation.notlogged',
        'Vous devez être loggué.'
      );
    if (Meteor.isServer) {
      const locationToDelete = Locations.find({
        ownerId: this.userId,
        value: value
      }).fetchAsync();

      await Locations.removeAsync({
        ownerId: this.userId,
        value: value
      });

      await Events.updateAsync(
        {
          ownerId: this.userId,
          location: locationToDelete.title
        },
        {
          $set: { location: '' }
        },
        { multi: true }
      );
    }
  }
});

export const addLocation = new ValidatedMethod({
  name: 'addlocation',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    title: String,
    value: String,
    bgColor: String
  }).validator(),

  run: async function (location) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.addlocation.notlogged',
        'Vous devez être loggué.'
      );
    if (Meteor.isServer) {
      const locationFind = await Locations.findOneAsync({
        ownerId: this.userId,
        value: location.value.toLowerCase()
      });

      if (!locationFind) {
        await Locations.insertAsync({
          ownerId: this.userId,
          ...location
        });
      }
    }
  }
});

export const updateLocation = new ValidatedMethod({
  name: 'updatelocation',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    title: String,
    value: String,
    bgColor: String
  }).validator(),

  run: async function (location) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.updatelocation.notlogged',
        'Vous devez être loggué.'
      );
    if (Meteor.isServer) {
      console.log('location :', location);
      const locationFind = await Locations.findOneAsync({
        ownerId: this.userId,
        value: location.value.toLowerCase()
      });

      if (locationFind) {
        await Locations.updateAsync(
          {
            ownerId: this.userId,
            value: location.value.toLowerCase()
          },
          {
            $set: {
              title: location.title,
              value: location.title.toLowerCase(),
              bgColor: location.bgColor
            }
          }
        );
        await Events.updateAsync(
          {
            ownerId: this.userId,
            location: locationFind.title
          },
          {
            $set: { location: location.title }
          },
          {
            multi: true
          }
        );
      }
    }
  }
});
