import { Mongo } from 'meteor/mongo';
import { ILocation } from '../interfaces/location-interface';

import { z } from 'zod';

const locationSchema = z.object({
  title: z.string(), // OK
  value: z.string(), // OK
  bgColor: z.string(),
  deleted: z.boolean(), // OK
  ownerId: z.string() // OK
});

const Locations = new Mongo.Collection<ILocation>('locations', {
  idGeneration: 'MONGO'
});

if (Meteor.isServer) {
  Locations.withSchema(locationSchema);
  Locations.withDates();
}

export default Locations;
