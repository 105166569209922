import { Mongo } from 'meteor/mongo';
import { Category } from '../interfaces/category-interface';

import { z } from 'zod';

const categoriesSchema = z.object({
  title: z.string(), // OK
  calendarId: z.string(),
  config: z.object({
    colorName: z.string(),
    lightColors: z.object({
      main: z.string(),
      onContainer: z.string(),
      container: z.string()
    }),
    darkColors: z.object({
      main: z.string(),
      onContainer: z.string(),
      container: z.string()
    })
  }),
  deleted: z.boolean(), // OK
  ownerId: z.string() // OK
});

const Categories = new Mongo.Collection<Category>('categories', {
  idGeneration: 'MONGO'
});

if (Meteor.isServer) {
  Categories.withSchema(categoriesSchema);
  Categories.withDates();
  Categories.withUser();
}

export default Categories;
