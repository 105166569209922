import { createStore } from 'vuex';
import log from 'loglevel';
//Vue.use(Vuex);

const store = createStore({
  state: {
    settings: {
      calendar: {
        dayBoundaries: {
          start: '00:00:00',
          end: '24:00:00'
        },
        event: {
          showEventHours: false
        }
      }
    },
    locationList: [
      {
        title: '',
        value: '',
        bgColor: '#FFFFFF'
      }
    ],
    appNavTitle: 'MonPlanningFacile by SASE',
    currentCalendarSetup: {
      start: new Date(),
      viewType: 'timeGridWeek'
    }
  },
  mutations: {
    setCurrentCalendarSetup(state, setup) {
      state.currentCalendarSetup = setup;
      log.info('setCurrentCalendarSetup :', setup);
    },

    setSettings(state, settings) {
      log.info('setSettings', settings);
      state.settings = settings;
    },
    setDayBoundariesCalendarSettings(state, settings) {
      state.settings.calendar.dayBoundaries = settings;
    },
    setShowEventHoursEventSettings(state, showEventHours) {
      state.settings.calendar.event.showEventHours = showEventHours;
    },

    setLocations(state, locations) {
      log.info('setLocations :', locations);
      state.locationList = locations;
    },
    addLocation(state, location) {
      state.locationList.push({
        title: location.title,
        value: location.value,
        bgColor: location.bgColor || '#FFFFFF'
      });
    },
    deleteLocation(state, index) {
      state.locationList.splice(index, 1);
    },
    updateLocation(state, location) {
      const index = state.locationList.findIndex(
        (loc) => loc.value === location.value
      );
      state.locationList[index] = {
        title: location.title,
        value: location.title.toLowerCase(),
        bgColor: location.bgColor
      };
    },

    setAppNavTitle(state, title) {
      state.appNavTitle = title;
    }
  },

  getters: {
    getSettings: (state) => {
      return state.settings;
    }
  },

  actions: {
    existingLocation({ state }, location) {
      return state.locationList.find((loc) => loc.value === location.value);
    }
  }
});

export default store;
