import { Mongo } from 'meteor/mongo';

import { z } from 'zod';

const eventsSchema = z.object({
  id: z.string(), // OK
  title: z.string(), // OK
  description: z.string().nullable(), // OK
  calendarId: z.string(), // OK
  ownerId: z.string(), // OK
  start: z.string(), // OK
  end: z.string(), // OK
  location: z.string(), // OK
  deleted: z.boolean() // OK
});

const Events = new Mongo.Collection('events', {
  idGeneration: 'MONGO'
});

if (Meteor.isServer) {
  Events.withSchema(eventsSchema);
  Events.withDates();
  Events.withUser();
}

export default Events;
