//import { ValidatedMethod } from 'meteor/mdg:validated-method';
import { Meteor } from 'meteor/meteor';
import SimpleSchema from 'simpl-schema';
import { Random } from 'meteor/random';
import Events from '../collections/events';
import TopEvents from '../collections/topEvents';
import { DateTime } from 'luxon';
import Locations from '../collections/locations';

async function updateTopUsedEvents(userId) {
  if (Meteor.isClient) return;

  let getTopUsedEvents = await Events.rawCollection()
    .aggregate([
      {
        $project: {
          ownerId: 1,
          calendarId: 1,
          title: 1,
          deleted: 1
        }
      },
      {
        $match: {
          ownerId: userId,
          deleted: false
        }
      },
      {
        $group: {
          _id: {
            ownerId: '$ownerId',
            calendarId: '$calendarId',
            title: '$title'
          },
          total: {
            $sum: 1
          }
        }
      },
      {
        $sort: {
          total: -1
        }
      }
    ])
    .toArray();

  getTopUsedEvents.forEach(async (event) => {
    if (
      !(await TopEvents.findOneAsync({
        ownerId: userId,
        calendarId: event._id.calendarId,
        title: event._id.title
      }))
    ) {
      await TopEvents.insertAsync({
        ownerId: userId,
        calendarId: event._id.calendarId,
        title: event._id.title,
        total: event.total
      });
    } else {
      await TopEvents.updateAsync(
        {
          ownerId: userId,
          calendarId: event._id.calendarId,
          title: event._id.title
        },
        {
          $set: {
            ownerId: userId,
            calendarId: event._id.calendarId,
            title: event._id.title,
            total: event.total
          }
        }
      );
    }
  });
}

/**
 * Adds an entry to the diary.
 *
 * @param {Object} options - The options for adding the diary entry.
 * @param {string} options.text - The text of the diary entry.
 * @param {string} options.date - The date of the diary entry. Example : "2020-01-04T21:32:02+03:00"
 * @param {string|Object} options.idAnimal - The ID of the animal or an object representing the animal.
 * @param {string} options.entryDate - The entry date of the diary entry.
 * @returns {Promise<string>} A promise that resolves to the ID of the inserted diary entry.
 * @throws {Meteor.Error} If the user is not logged in or is not authorized to add the diary entry.
 */
export const addEvent = new ValidatedMethod({
  name: 'addevent',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    id: { type: String },
    title: { type: String },
    description: { type: String, optional: true },
    calendarId: { type: String },
    location: { type: Object, optional: true },
    'location.title': { type: String },
    'location.value': { type: String },
    'location.bgColor': { type: String, optional: true },
    start: { type: String },
    end: { type: String }
  }).validator(),

  run: async function ({
    id,
    title,
    description,
    calendarId,
    location,
    start,
    end
  }) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.addentrydiary.notlogged',
        'Vous devez être loggué.'
      );

    //	log.info(Animal);
    if (!location) location = '';
    if (!description) description = null;

    const locationFind = await Locations.findOneAsync({
      ownerId: this.userId,
      value: location.value.toLowerCase()
    });

    if (!locationFind) {
      await Locations.insertAsync({
        title: location.title,
        ownerId: this.userId,
        bgColor: '#FFFFFF',
        value: location.value
      });
    }

    let returnId = await Events.insertAsync({
      id,
      title,
      description,
      calendarId,
      ownerId: this.userId,
      location: location.title,
      start,
      end,
      deleted: false
    });

    await updateTopUsedEvents(this.userId);

    return returnId;
  }
});

export const updateEvent = new ValidatedMethod({
  name: 'updateevent',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    id: { type: String },
    title: { type: String },
    description: { type: String, optional: true },
    calendarId: { type: String },
    location: { type: Object, optional: true },
    'location.title': { type: String },
    'location.value': { type: String },
    'location.bgColor': { type: String, optional: true },
    start: { type: String },
    end: { type: String }
  }).validator(),

  run: async function ({
    id,
    title,
    description,
    calendarId,
    location,
    start,
    end
  }) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.updateevent.notlogged',
        'Vous devez être loggué.'
      );

    if (!location) location = '';
    if (!description) description = null;

    const locationFind = await Locations.findOneAsync({
      ownerId: this.userId,
      value: location.value.toLowerCase()
    });

    if (!locationFind) {
      await Locations.insertAsync({
        title: location.title,
        ownerId: this.userId,
        bgColor: '#FFFFFF',
        value: location.value
      });
    }

    let returnId = await Events.updateAsync(
      { id: id },
      {
        $set: {
          id,
          title,
          description,
          calendarId,
          ownerId: this.userId,
          location: location.title,
          start,
          end
        }
      }
    );

    await updateTopUsedEvents(this.userId);

    return returnId;
  }
});

export const deleteEvent = new ValidatedMethod({
  name: 'deleteevent',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    id: { type: String }
  }).validator(),

  run: async function ({ id }) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.updateevent.notlogged',
        'Vous devez être loggué.'
      );

    let returnId = await Events.removeAsync({ id: id });

    await updateTopUsedEvents(this.userId);

    return returnId;
  }
});

export const copyWeek = new ValidatedMethod({
  name: 'copyweek',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    date: { type: String },
    nbDays: { type: Number },
    dateToStartCopy: { type: String }
  }).validator(),

  run: async function ({ date, nbDays, dateToStartCopy }) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.copyweek.notlogged',
        'Vous devez être loggué.'
      );

    const dateStart = DateTime.fromISO(date.split(' ')[0]);

    const nbDaysBetweenFirstDateAndDateToStartCopy = Math.abs(
      dateStart.diff(DateTime.fromISO(dateToStartCopy), 'days').days
    );

    for (let i = 0; i < nbDays; i++) {
      const events = await Events.find({
        start: {
          $gte: dateStart.plus({ days: i }).toISO().split('T')[0],
          $lt: dateStart
            .plus({ days: i + 1 })
            .toISO()
            .split('T')[0]
        },
        ownerId: this.userId
      }).fetchAsync();

      for (const event of events) {
        let startDate = event.start.split(' ')[0];
        let startTime = event.start.split(' ')[1];
        let endDate = event.end.split(' ')[0];
        let endTime = event.end.split(' ')[1];

        startDate = DateTime.fromISO(startDate)
          .plus({ days: nbDaysBetweenFirstDateAndDateToStartCopy })
          .toISO();
        endDate = DateTime.fromISO(endDate)
          .plus({ days: nbDaysBetweenFirstDateAndDateToStartCopy })
          .toISO();
        const newEvent = {
          id: Random.id(),
          title: event.title,
          description: event.description,
          calendarId: event.calendarId,
          ownerId: this.userId,
          location: event.location,
          start: startDate.split('T')[0] + ' ' + startTime,
          end: endDate.split('T')[0] + ' ' + endTime,
          deleted: false
        };

        await Events.insertAsync(newEvent);
      }
    }

    await updateTopUsedEvents(this.userId);
  }
});
