//import { ValidatedMethod } from 'meteor/mdg:validated-method';
import { Meteor } from 'meteor/meteor';
import SimpleSchema from 'simpl-schema';
import Settings from '../collections/settings';

/**
 * Method to set the day boundaries in the settings.
 *
 * @method setSettingsDayBoundaries
 * @memberof Methods
 * @instance
 * @param {Object} options - The options object.
 * @param {string} options.start - The start time boundary.
 * @param {string} options.end - The end time boundary.
 * @throws {Meteor.Error} If the user is not logged in.
 * @returns {Promise<string>} The ID of the inserted settings document.
 */
export const setSettings = new ValidatedMethod({
  name: 'setsettings',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    start: { type: String },
    end: { type: String },
    showEventHours: { type: Boolean }
  }).validator(),

  run: async function ({ start, end, showEventHours }) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.setsettings.notlogged',
        'Vous devez être loggué.'
      );

    // Tester si les dates sont valides

    const existingSettings = await Settings.findOneAsync({
      ownerId: this.userId
    });

    const dayBoundaries = {
      calendar: {
        dayBoundaries: {
          start,
          end
        },
        event: {
          showEventHours
        }
      }
    };

    if (existingSettings) {
      return await Settings.updateAsync(
        { ownerId: this.userId },
        { $set: dayBoundaries }
      );
    } else {
      return await Settings.insertAsync({
        ownerId: this.userId,
        ...dayBoundaries,
        deleted: false
      });
    }
  }
});

export const setDayBoundarieCalendarSettings = new ValidatedMethod({
  name: 'setdayboundariescalendarsettings',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    start: { type: String },
    end: { type: String }
  }).validator(),

  run: async function ({ start, end }) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.setdayboundariescalendarsettings.notlogged',
        'Vous devez être loggué.'
      );

    // Tester si les dates sont valides

    const existingSettings = await Settings.findOneAsync({
      ownerId: this.userId
    });

    /*   const dayBoundaries = {
      calendar: {
        dayBoundaries: {
          start,
          end
        },
        event: {
          showEventHours
        }
      }
    }; */

    if (existingSettings) {
      return await Settings.updateAsync(
        { ownerId: this.userId },
        { $set: { 'calendar.dayBoundaries': { start, end } } }
      );
    } else {
      return await Settings.insertAsync({
        ownerId: this.userId,
        calendar: {
          dayBoundaries: { start, end },
          event: {
            showEventHours: false
          }
        },
        deleted: false
      });
    }
  }
});

export const setShowEventHoursEventSettings = new ValidatedMethod({
  name: 'setShowEventHoursEventSettings',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    showEventHours: { type: Boolean }
  }).validator(),

  run: async function ({ showEventHours }) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.setShowEventHoursEventSettings.notlogged',
        'Vous devez être loggué.'
      );

    // Tester si les dates sont valides

    const existingSettings = await Settings.findOneAsync({
      ownerId: this.userId
    });

    /*   const dayBoundaries = {
      calendar: {
        dayBoundaries: {
          start,
          end
        },
        event: {
          showEventHours
        }
      }
    }; */

    if (existingSettings) {
      return await Settings.updateAsync(
        { ownerId: this.userId },
        { $set: { 'calendar.event.showEventHours': showEventHours } }
      );
    } else {
      return await Settings.insertAsync({
        ownerId: this.userId,
        calendar: {
          dayBoundaries: { start: '00:00:00', end: '24:00:00' },
          event: {
            showEventHours
          }
        },
        deleted: false
      });
    }
  }
});

/**
 * Method to get user settings.
 *
 * @name getsettings
 * @rateLimit {Object} rateLimit - Rate limit configuration.
 * @rateLimit.numRequests {number} - Number of requests allowed.
 * @rateLimit.timeInterval {number} - Time interval for rate limiting in milliseconds.
 * @validate {SimpleSchema} - Schema validator.
 *
 * @throws {Meteor.Error} If the user is not logged in.
 *
 * @returns {Promise<Object>} User settings.
 */
export const getSettings = new ValidatedMethod({
  name: 'getsettings',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({}).validator(),

  run: async function ({}) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.setsettingsdayboundaries.notlogged',
        'Vous devez être loggué.'
      );
    if (Meteor.isServer) {
      return await Settings.findOneAsync({
        ownerId: this.userId
      });
    }
  }
});
