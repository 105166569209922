import { Meteor } from 'meteor/meteor';
import { createApp } from 'vue';
import { VueMeteor } from 'vue-meteor-tracker';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import VuetifyNotifier from 'vuetify-notifier';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { Tracker } from 'meteor/tracker';
import { TimeSync } from 'meteor/mizzao:timesync';
import { UserStatus } from 'meteor/mizzao:user-status';
import '/imports/startup/serviceworker';

import '/imports/api/config/soft-delete';
import '/imports/api/config/pub-sub';
import '/imports/api/config/offline';

import '/imports/api/methods/index';

import App from './ViewHome.vue';

import store from './plugins/store';
import { router } from './router';

import mitt from 'mitt';
const EventBus = mitt();

import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

//import '@fortawesome/fontawesome-free/css/all.css'; // Ensure your project is capable of handling css files
//import '@mdi/font/css/materialdesignicons.css';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import {
  mdiLogout,
  mdiContentCopy,
  mdiPlus,
  mdiCog,
  mdiEye,
  mdiEyeOff,
  mdiShield,
  mdiPencil,
  mdiDelete,
  mdiEmailArrowRight,
  mdiGavel,
  mdiCalendarBlank,
  mdiCalendarOutline,
  mdiChartBellCurveCumulative,
  mdiMapMarker,
  mdiTextBoxOutline,
  mdiAccount
} from '@mdi/js';

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      logout: mdiLogout,
      copy: mdiContentCopy,
      plus: mdiPlus,
      settings: mdiCog,
      eye: mdiEye,
      eyeOff: mdiEyeOff,
      shield: mdiShield,
      pencil: mdiPencil,
      delete: mdiDelete,
      sendEmail: mdiEmailArrowRight,
      gavel: mdiGavel,
      calendar: mdiCalendarBlank,
      event: mdiCalendarOutline,
      bellCurveCumulative: mdiChartBellCurveCumulative,
      location: mdiMapMarker,
      description: mdiTextBoxOutline,
      account: mdiAccount
    },

    sets: {
      mdi
    }
  },
  // components,
  directives,
  components: { ...components },
  theme: {
    defaultTheme: 'myCustomLightTheme',

    themes: {
      myCustomLightTheme: {
        dark: false,
        colors: {
          background: '#FFFFFF',
          surface: '#FFFFFF',
          primary: '#3167aa',
          'primary-darken-1': '#3700B3',
          secondary: '#03DAC6',
          'secondary-darken-1': '#018786',
          error: '#B00020',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00'
        }
      }
    }
  }
});

Meteor.startup(() => {
  const app = createApp(App);
  app.config.globalProperties.emitter = EventBus;
  app.use(router);
  app.use(store);
  app.use(VueMeteor);
  app.use(vuetify);
  app.use(VuetifyNotifier, {
    default: {
      defaultColor: 'primary', //default color for all notifications
      closeIcon: 'mdi-close' //default close icon for component
    }
  });
  app.use(VueReCaptcha, {
    siteKey: Meteor.settings.public.reCaptcha.siteKey,
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true
    }
  });
  app.mount('#app');

  Tracker.autorun(() => {
    if (TimeSync.isSynced()) {
      if (!UserStatus.isMonitoring)
        UserStatus.startMonitor({
          threshold: 30000,
          interval: 10000,
          idleOnBlur: false
        });
    }
  });
  // UserStatus.startMonitor({threshold: 30000, interval: 1000, idleOnBlur: true});
});
