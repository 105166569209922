//import { ValidatedMethod } from 'meteor/mdg:validated-method';
import { Meteor } from 'meteor/meteor';
import SimpleSchema from 'simpl-schema';
import Categories from '../collections/categories';
//if (Meteor.isServer) var admin = require('firebase-admin');

export const getCategories = new ValidatedMethod({
  name: 'getcategories',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({}).validator(),

  run: async function ({}) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.getcategories.notlogged',
        'Vous devez être loggué.'
      );

    return await Categories.find(
      { ownerId: this.userId },
      { fields: { _id: 0, title: 1, calendarId: 1, config: 1 } }
    ).fetchAsync();
  }
});

export const updateCategory = new ValidatedMethod({
  name: 'updatecategory',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    title: { type: String },
    calendarId: { type: String },
    config: { type: Object },
    'config.colorName': { type: String },
    'config.lightColors': { type: Object },
    'config.lightColors.main': { type: String },
    'config.lightColors.onContainer': { type: String },
    'config.lightColors.container': { type: String },
    'config.darkColors': { type: Object },
    'config.darkColors.main': { type: String },
    'config.darkColors.onContainer': { type: String },
    'config.darkColors.container': { type: String }
  }).validator(),

  run: async function ({ title, calendarId, config }) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.getcategories.notlogged',
        'Vous devez être loggué.'
      );

    return await Categories.updateAsync(
      { calendarId: calendarId },
      { $set: { title: title, config: config } }
    );
  }
});

export const addCategory = new ValidatedMethod({
  name: 'addcategory',
  rateLimit: {
    numRequests: 5,
    timeInterval: 5000
  },
  validate: new SimpleSchema({
    title: { type: String },
    calendarId: { type: String },
    config: { type: Object },
    'config.colorName': { type: String },
    'config.lightColors': { type: Object },
    'config.lightColors.main': { type: String },
    'config.lightColors.onContainer': { type: String },
    'config.lightColors.container': { type: String },
    'config.darkColors': { type: Object },
    'config.darkColors.main': { type: String },
    'config.darkColors.onContainer': { type: String },
    'config.darkColors.container': { type: String }
  }).validator(),

  run: async function ({ title, calendarId, config }) {
    if (!this.userId)
      throw new Meteor.Error(
        'methods.getcategories.notlogged',
        'Vous devez être loggué.'
      );

    return await Categories.insertAsync({
      title,
      calendarId,
      config,
      ownerId: this.userId,
      deleted: false
    });
  }
});
