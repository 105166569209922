import { createRouter, createWebHistory } from 'vue-router';
const ViewApp = () => import('./Views/App.vue');
const ViewCalendar = () => import('./Views/Calendar/ViewCalendar.vue');
const ViewSignIn = () => import('./Views/Account/ViewSignIn.vue');
const ViewSignUp = () => import('./Views/Account/ViewSignUp.vue');
const ViewEnrollAccount = () => import('./Views/Account/ViewResetPassword.vue');
const ViewTermsOfUse = () => import('./Views/Account/ViewTermsOfUse.vue');
const ForgotPassword = () => import('./Views/Account/ViewResetPassword.vue');
const ViewAdmin = () => import('./Views/Admin/ViewAdmin.vue');

const ViewStats = () => import('./Views/Stats/ViewStatsMain.vue');
const ViewStatsCategories = () =>
  import('./Views/Stats/ViewStatsCategories.vue');
const ViewStatsEvents = () => import('./Views/Stats/ViewStatsEvents.vue');
const ViewStatsLocations = () => import('./Views/Stats/ViewStatsLocations.vue');

const ViewSettings = () => import('./Views/Settings/ViewSettingsMain.vue');
const ViewCalendarSettings = () =>
  import('./Views/Settings/ViewCalendarSettings.vue');
const ViewEventsSettings = () =>
  import('./Views/Settings/ViewEventsSettings.vue');
const ViewProfilSettings = () =>
  import('./Views/Settings/ViewProfilSettings.vue');
const ViewLocationsSettings = () =>
  import('./Views/Settings/ViewLocationsSettings.vue');

import ViewHome from './ViewHome.vue';
import log from 'loglevel';

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      meta: { requiresAuth: true },
      component: ViewHome
    },
    {
      path: '/app',
      name: 'app',
      meta: { requiresAuth: true },
      component: ViewApp,
      children: [
        {
          path: 'calendar',
          name: 'ViewCalendar',
          meta: { requiresAuth: true },
          component: ViewCalendar
        },
        {
          path: 'admin',
          name: 'ViewAdmin',
          component: ViewAdmin,
          meta: { requiresAuth: true }
        },
        {
          path: '/app/stats',
          name: 'ViewStats',
          component: ViewStats,
          meta: { requiresAuth: true },
          children: [
            {
              path: 'categories',
              name: 'ViewStatsCategories',
              component: ViewStatsCategories,
              meta: { requiresAuth: true }
            },
            {
              path: 'events',
              name: 'ViewStatsEvents',
              component: ViewStatsEvents,
              meta: { requiresAuth: true }
            },
            {
              path: 'locations',
              name: 'ViewStatsLocations',
              component: ViewStatsLocations,
              meta: { requiresAuth: true }
            },
            {
              path: '',
              name: 'ViewStatssDefault',
              component: ViewStatsCategories,
              meta: { requiresAuth: true }
            }
          ]
        },
        {
          path: '/app/settings',
          name: 'ViewSettings',
          component: ViewSettings,
          meta: { requiresAuth: true },
          children: [
            {
              path: 'calendar',
              name: 'ViewCalendarSettings',
              component: ViewCalendarSettings,
              meta: { requiresAuth: true }
            },
            {
              path: 'events',
              name: 'ViewEventsSettings',
              component: ViewEventsSettings,
              meta: { requiresAuth: true }
            },
            {
              path: 'profil',
              name: 'ViewProfilSettings',
              component: ViewProfilSettings,
              meta: { requiresAuth: true }
            },
            {
              path: 'locations',
              name: 'ViewLocationsSettings',
              component: ViewLocationsSettings,
              meta: { requiresAuth: true }
            },
            {
              path: '',
              name: 'ViewSettingsDefault',
              component: ViewCalendarSettings,
              meta: { requiresAuth: true }
            }
          ]
        }
      ]
    },

    {
      path: '/signin',
      name: 'ViewSignIn',
      component: ViewSignIn,
      meta: { MustNotBeAuth: true }
    },

    {
      path: '/signup',
      name: 'ViewSignUp',
      component: ViewSignUp,
      meta: { MustNotBeAuth: true }
    },
    {
      path: '/termsofuse',
      name: 'ViewTermsOfUse',
      component: ViewTermsOfUse,
      meta: { MustNotBeAuth: true }
    },
    {
      path: '/enroll-account/:token',
      name: 'ViewEnrollAccount',
      component: ViewEnrollAccount,
      meta: { MustNotBeAuth: true }
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: ForgotPassword
    }
  ]
});

router.beforeEach((to, from) => {
  log.info('TO :', to);
  log.info('FROM : ', from);
  // next();
  //log.infot("RECORD ;", record);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!Meteor.userId()) {
      log.info('Non Authentifié');
      return {
        path: '/signin',
        query: { redirect: to.fullPath }
      };
    } else {
      log.info('Authentifié');
      if (from.redirectedFrom === undefined && to.path === '/')
        return {
          path: '/app/calendar/'
        };

      //next({ path: to.fullPath, force: true });
    }
  } else {
    log.info('Authentification non requise');
    if (to.matched.some((record) => record.meta.MustNotBeAuth)) {
      if (Meteor.userId()) {
        // Utilisateur déjà authentifié
        return {
          path: '/app/calendar/'
        };
      } else {
      }
    }
  }
});

export default router;
